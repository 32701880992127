import React, { useState, useEffect, useRef } from 'react';

const COLORS = [
    '#6366f1', '#4f46e5', '#4338ca', // Indigo shades
    '#2563eb', '#1d4ed8', '#1e40af', // Blue shades
    '#7c3aed', '#6d28d9', '#5b21b6', // Purple shades
    '#2dd4bf', '#14b8a6', '#0d9488', // Teal shades
    '#8b5cf6', '#7c3aed', '#6d28d9'  // Violet shades
];

const AnimatedBackground = () => {
    const particlesRef = useRef({ large: [], small: [] });
    const requestRef = useRef();
    const canvasRef = useRef(null);
    const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 });

    const createParticle = (width, height, isLarge = false) => {
        const size = isLarge ? 
            Math.random() * 200 + 150 : 
            Math.random() * 100 + 50;   
        
        return {
            x: Math.random() * width,
            y: Math.random() * height,
            size,
            baseSize: size,
            speedX: (Math.random() - 0.5) * (isLarge ? 0.2 : 0.6),
            speedY: (Math.random() - 0.5) * (isLarge ? 0.2 : 0.6),
            color: COLORS[Math.floor(Math.random() * COLORS.length)],
            targetColor: COLORS[Math.floor(Math.random() * COLORS.length)],
            colorProgress: 0,
            pulseDirection: 1,
            pulseSpeed: Math.random() * 0.015 + 0.008,
            rotationAngle: Math.random() * Math.PI * 2,
            rotationSpeed: (Math.random() - 0.5) * 0.008,
            opacity: isLarge ? Math.random() * 0.25 + 0.15 : Math.random() * 0.4 + 0.25
        };
    };

    const lerp = (start, end, t) => start * (1 - t) + end * t;

    const lerpColor = (color1, color2, t) => {
        const r1 = parseInt(color1.slice(1, 3), 16);
        const g1 = parseInt(color1.slice(3, 5), 16);
        const b1 = parseInt(color1.slice(5, 7), 16);
        const r2 = parseInt(color2.slice(1, 3), 16);
        const g2 = parseInt(color2.slice(3, 5), 16);
        const b2 = parseInt(color2.slice(5, 7), 16);
        
        const r = Math.round(lerp(r1, r2, t));
        const g = Math.round(lerp(g1, g2, t));
        const b = Math.round(lerp(b1, b2, t));
        
        return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    };

    useEffect(() => {
        const generateParticles = () => {
            const largeParticles = Array(12).fill(null).map(() => 
                createParticle(canvasDimensions.width, canvasDimensions.height, true)
            );
            const smallParticles = Array(20).fill(null).map(() =>
                createParticle(canvasDimensions.width, canvasDimensions.height, false)
            );
            particlesRef.current = { large: largeParticles, small: smallParticles };
        };

        if (canvasDimensions.width && canvasDimensions.height) {
            generateParticles();
        }
    }, [canvasDimensions]);

    useEffect(() => {
        const drawParticle = (ctx, particle, blur = false) => {
            ctx.save();
            if (blur) {
                ctx.filter = `blur(${10 + Math.sin(particle.pulseDirection) * 3}px)`;
            }
            
            const currentColor = lerpColor(particle.color, particle.targetColor, particle.colorProgress);
            const currentSize = particle.baseSize * (1 + Math.sin(particle.pulseDirection) * 0.25);
            
            ctx.translate(particle.x, particle.y);
            ctx.rotate(particle.rotationAngle);
            
            const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, currentSize);
            gradient.addColorStop(0, `${currentColor}${Math.floor((particle.opacity + 0.2) * 255).toString(16).padStart(2, '0')}`);
            gradient.addColorStop(0.5, `${currentColor}${Math.floor((particle.opacity + 0.1) * 255).toString(16).padStart(2, '0')}`);
            gradient.addColorStop(0.8, `${currentColor}${Math.floor(particle.opacity * 0.3 * 255).toString(16).padStart(2, '0')}`);
            gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
            
            ctx.fillStyle = gradient;
            ctx.beginPath();
            ctx.arc(0, 0, currentSize, 0, Math.PI * 2);
            ctx.fill();
            
            ctx.restore();
        };

        const animate = () => {
            const canvas = canvasRef.current;
            if (!canvas) return;
            
            const ctx = canvas.getContext('2d', { alpha: false });
            ctx.fillStyle = '#020617';
            ctx.fillRect(0, 0, canvasDimensions.width, canvasDimensions.height);

            particlesRef.current.large.forEach(particle => {
                drawParticle(ctx, particle, true);
            });

            particlesRef.current.small.forEach(particle => {
                drawParticle(ctx, particle);
            });

            [...particlesRef.current.large, ...particlesRef.current.small].forEach(particle => {
                particle.x += particle.speedX;
                particle.y += particle.speedY;
                particle.rotationAngle += particle.rotationSpeed;
                particle.colorProgress += 0.004;
                
                if (particle.colorProgress >= 1) {
                    particle.color = particle.targetColor;
                    particle.targetColor = COLORS[Math.floor(Math.random() * COLORS.length)];
                    particle.colorProgress = 0;
                }
                
                particle.pulseDirection += particle.pulseSpeed;
                
                // Wrap particles around the screen
                if (particle.x < -particle.size) particle.x = canvasDimensions.width + particle.size;
                if (particle.x > canvasDimensions.width + particle.size) particle.x = -particle.size;
                if (particle.y < -particle.size) particle.y = canvasDimensions.height + particle.size;
                if (particle.y > canvasDimensions.height + particle.size) particle.y = -particle.size;
            });

            requestRef.current = requestAnimationFrame(animate);
        };

        if (canvasDimensions.width && canvasDimensions.height) {
            requestRef.current = requestAnimationFrame(animate);
        }

        return () => {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, [canvasDimensions]);

    useEffect(() => {
        const updateDimensions = () => {
            if (canvasRef.current) {
                const { clientWidth, clientHeight } = canvasRef.current.parentElement;
                const dpr = window.devicePixelRatio || 1;
                setCanvasDimensions({
                    width: clientWidth,
                    height: clientHeight
                });
                canvasRef.current.width = clientWidth * dpr;
                canvasRef.current.height = clientHeight * dpr;
                canvasRef.current.style.width = `${clientWidth}px`;
                canvasRef.current.style.height = `${clientHeight}px`;
                const ctx = canvasRef.current.getContext('2d');
                ctx.scale(dpr, dpr);
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full" />;
};

const SkyZaiLogo = () => {
  const containerRef = useRef(null);
  const [size, setSize] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const goldenRatio = 1.618;

  useEffect(() => {
      const updateSize = () => {
          if (containerRef.current) {
              const container = containerRef.current;
              const shortestSide = Math.min(container.offsetWidth, container.offsetHeight);
              setSize(shortestSide / goldenRatio);
          }
      };

      updateSize();
      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
      <div className="relative w-full h-screen bg-slate-950 overflow-hidden" ref={containerRef}>
          <AnimatedBackground />
          <div 
              className="absolute inset-0 backdrop-blur-4xl transition-all duration-1000" 
              style={{ backdropFilter: `blur(${isHovered ? '55px' : '45px'})` }} 
          />
          
          <div 
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform duration-1000"
              style={{ 
                  transform: `translate(-50%, -50%) scale(${isHovered ? 1.05 : 1})`,
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
          >
              <svg 
                  width={size} 
                  height={size} 
                  viewBox="0 0 555 555" 
                  className="transition-all duration-1000"
              >
                  <defs>
                      <filter id="hover-glow">
                          <feGaussianBlur stdDeviation="4" />
                          <feComposite in2="SourceGraphic" operator="over" />
                          <feDropShadow dx="0" dy="0" stdDeviation="8" floodColor="white" floodOpacity="0.3" />
                      </filter>
                  </defs>
                  
                  <g 
                      className="transition-all duration-700"
                      style={{ 
                          filter: isHovered ? 'url(#hover-glow)' : 'none',
                          opacity: isHovered ? 1 : 0.7,
                      }}
                  >
                      <path d="M244.232 92.391L277.173 0L310.114 92.391L277.173 184.782L244.232 92.391Z" fill="#FFFDE7"/>
                      <path d="M341.036 100.676L415.76 37.1338L398.092 133.617L323.369 197.16L341.036 100.676Z" fill="#FFFDE7"/>
                      <path d="M420.728 156.254L517.212 138.586L453.669 213.309L357.186 230.977L420.728 156.254Z" fill="#FFFDE7"/>
                      <path d="M461.955 244.232L554.346 277.173L461.955 310.114L369.564 277.173L461.955 244.232Z" fill="#FFFDE7"/>
                      <path d="M453.669 341.037L517.212 415.76L420.728 398.092L357.186 323.369L453.669 341.037Z" fill="#FFFDE7"/>
                      <path d="M398.092 420.728L415.759 517.212L341.036 453.669L323.368 357.186L398.092 420.728Z" fill="#FFFDE7"/>
                      <path d="M310.114 461.955L277.173 554.346L244.232 461.955L277.173 369.564L310.114 461.955Z" fill="#FFFDE7"/>
                      <path d="M213.31 453.669L138.587 517.212L156.254 420.728L230.978 357.186L213.31 453.669Z" fill="#FFFDE7"/>
                      <path d="M133.617 398.092L37.134 415.759L100.677 341.036L197.16 323.368L133.617 398.092Z" fill="#FFFDE7"/>
                      <path d="M92.391 310.114L0 277.173L92.391 244.232L184.782 277.173L92.391 310.114Z" fill="#FFFDE7"/>
                      <path d="M100.677 213.31L37.1342 138.587L133.618 156.254L197.16 230.978L100.677 213.31Z" fill="#FFFDE7"/>
                      <path d="M156.254 133.617L138.587 37.134L213.31 100.677L230.978 197.16L156.254 133.617Z" fill="#FFFDE7"/>
                  </g>
              </svg>
          </div>
      </div>
  );
};

export default SkyZaiLogo;
